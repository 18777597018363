/* eslint-disable react/display-name */

import * as React from "react";
import redraft, { Renderers } from "redraft";
import { RichTextBlockData } from "src/blocks.generated";

import * as sc from "./RichTextBlock.sc";

const defaultRenderers: Renderers = {
    inline: {
        BOLD: (children, { key }) => <strong key={key}>{children}</strong>,
        ITALIC: (children, { key }) => <em key={key}>{children}</em>,
    },
    blocks: {
        // Paragraph
        unstyled: (children, { keys }) => children.map((child, idx) => <sc.Paragraph key={keys[idx]}>{child}</sc.Paragraph>),
        // Headlines
        "header-one": (children, { keys }) =>
            children.map((child, idx) => (
                <React.Fragment key={keys[idx]}>
                    <sc.HorizontalLine />
                    <sc.Heading1>{child}</sc.Heading1>
                </React.Fragment>
            )),
        "header-two": (children, { keys }) => children.map((child, idx) => <sc.Heading2 key={keys[idx]}>{child}</sc.Heading2>),
        "header-three": (children, { keys }) => children.map((child, idx) => <sc.Heading3 key={keys[idx]}>{child}</sc.Heading3>),
        "header-four": (children, { keys }) => children.map((child, idx) => <sc.Heading4 key={keys[idx]}>{child}</sc.Heading4>),
        "header-five": (children, { keys }) => children.map((child, idx) => <sc.Heading5 key={keys[idx]}>{child}</sc.Heading5>),
        "header-six": (children, { keys }) => children.map((child, idx) => <sc.Heading6 key={keys[idx]}>{child}</sc.Heading6>),
        // List
        // or depth for nested lists
        "unordered-list-item": (children, { depth, keys }) => (
            <sc.UnorderedList key={keys[keys.length - 1]} className={`ul-level-${depth}`}>
                {children.map((child, index) => (
                    <sc.ListItem key={keys[index]}>{child}</sc.ListItem>
                ))}
            </sc.UnorderedList>
        ),
        "ordered-list-item": (children, { depth, keys }) => (
            <ol key={keys.join("|")} className={`ol-level-${depth}`}>
                {children.map((child, index) => (
                    <sc.ListItem key={keys[index]}>{child}</sc.ListItem>
                ))}
            </ol>
        ),
    },
};

interface RichTextBlockProps extends RichTextBlockData {
    renderers?: Renderers;
    variant?: "light" | "dark";
}

export const RichTextBlock: React.FC<RichTextBlockProps> = ({ draftContent, renderers = defaultRenderers, variant = "dark" }) => {
    return <sc.RichTextContainer variant={variant}>{redraft(draftContent, renderers)}</sc.RichTextContainer>;
};
