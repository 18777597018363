import * as React from "react";
import redraft, { Renderers } from "redraft";
import { HeadlineBlockData } from "src/blocks.generated";
import styled from "styled-components";

export type HeadlineSections = "header-one" | "header-two" | "header-three";

type HeadlineRendersFn = (options: { headlineSection: HeadlineSections }) => Renderers;

const headlineRenderers: HeadlineRendersFn = ({ headlineSection }) => {
    return {
        inline: {
            // eslint-disable-next-line react/display-name
            BOLD: (children, { key }) => <strong key={key}>{children}</strong>,
            // eslint-disable-next-line react/display-name
            ITALIC: (children, { key }) => <em key={key}>{children}</em>,
        },
        blocks: {
            unstyled: (children) => {
                switch (headlineSection) {
                    case "header-one":
                        return children.map((child, index) => <Heading1 key={index}>{child}</Heading1>);
                    case "header-two":
                        return children.map((child, index) => <Heading2 key={index}>{child}</Heading2>);
                    case "header-three":
                        return children.map((child, index) => <Heading3 key={index}>{child}</Heading3>);
                    default:
                        return children.map((child, index) => <Body key={index}>{child}</Body>);
                }
            },
        },
    };
};

export interface HeadlineProps extends HeadlineBlockData {
    variant?: "light" | "dark";
}

export function HeadlineBlock({ headline, headlineSection, variant = "dark" }: HeadlineProps): React.ReactElement {
    return (
        <HeadlineContainer variant={variant}>
            <HorizontalLine />
            {redraft(headline.draftContent, headlineRenderers({ headlineSection }))}
        </HeadlineContainer>
    );
}

interface HeadlineContainerProps {
    variant: "light" | "dark";
}

const HeadlineContainer = styled.div<HeadlineContainerProps>`
    margin: 40px 0;
    padding: 0 20px;
    color: ${({ theme, variant }) => (variant === "dark" ? theme.colors.porscheBankBlue : theme.colors.white)};
    text-transform: uppercase;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        padding: 0 70px;
        margin: 80px 0;
    }
    ${({ theme }) => theme.breakpoints.xl.mediaquery} {
        padding: 0 140px;
    }
`;

const HorizontalLine = styled.div`
    border-top: 3px solid ${({ theme }) => theme.colors.darkRed};
    width: 76px;
`;

export const Heading1 = styled.h1`
    margin-bottom: 30px;
    font-size: 26px;
    line-height: 30px;
    font-weight: 500;
    margin-top: 19px;

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        margin-bottom: 40px;
        font-size: 32px;
        line-height: 40px;
    }
`;

export const Heading2 = styled.h2`
    margin-bottom: 10px;
    font-size: 23px;
    line-height: 27px;
    font-weight: 500;
    margin-top: 19px;

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        margin-bottom: 20px;
        font-size: 29px;
        line-height: 37px;
    }
`;

export const Heading3 = styled.h3`
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    margin-top: 19px;

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        margin-bottom: 20px;
        font-size: 26px;
        line-height: 34px;
    }
`;

export const Body = styled.p`
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 18px;

    ${({ theme }) => theme.breakpoints.sm.mediaquery} {
        font-size: 14px;
        line-height: 20px;
    }
    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        font-size: 16px;
        line-height: 24px;
    }
`;
