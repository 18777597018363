import * as CheckMark from "@src/shared/assets/images/check_mark.inline.svg";
import styled from "styled-components";

export const ContentWrapper = styled.div`
    display: flex;
    min-height: 100px;
    align-self: baseline;
    flex-basis: 100%;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        flex-basis: calc(33% - 40px);
    }
`;

export const Image = styled.img`
    max-height: 100px;
    max-width: 100px;
    margin-right: 20px;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 15px 0;
    max-width: 300px;

    ${({ theme }) => theme.breakpoints.xl.mediaquery} {
        max-width: 400px;
    }
`;

export const Title = styled.p`
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    margin: 0;
    margin-bottom: 15px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        font-size: 20px;
        line-height: 34px;
    }
`;

export const Text = styled.p`
    font-size: 16px;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    margin: 0;
`;

export const CheckCircle = styled(CheckMark)`
    color: ${({ theme }) => theme.colors.white};
    margin: 20px 10px 15px 0;
    min-width: 24px;
    background: ${({ theme }) => theme.colors.porscheBankBlue};
    border-radius: 50%;
`;
