import * as React from "react";
import { TextImageBlockData } from "src/blocks.generated";
import styled from "styled-components";

import { ImageBlock } from "../Image/ImageBlock";
import { RichTextBlock } from "../RTE/RichTextBlock";

export function TextImage({ text, image, imageAspectRatio, imagePosition }: TextImageBlockData): React.ReactElement {
    return (
        <TextImageContainer>
            <TextImageContent imagePosition={imagePosition}>
                <RichTextContainer>
                    <RichTextBlock variant="dark" draftContent={text.draftContent} />
                </RichTextContainer>
                {image.damFile && (
                    <ImageBlockWrapper>
                        <ImageBlock aspectRatio={imageAspectRatio} data={image} />
                    </ImageBlockWrapper>
                )}
            </TextImageContent>
        </TextImageContainer>
    );
}

const TextImageContainer = styled.div`
    margin: -40px 0 40px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        margin: 80px 0;
    }
`;

const RichTextContainer = styled.div`
    flex-basis: 50%;
`;

interface TextImageContentProps {
    imagePosition: "left" | "right";
}

const TextImageContent = styled.div<TextImageContentProps>`
    display: flex;
    flex-direction: ${({ imagePosition }) => (imagePosition === "right" ? "column" : "column-reverse")};
    gap: 20px;
    background-color: ${({ theme }) => theme.colors.backgroundLightGray};
    padding: 40px 20px;
    z-index: 1;
    position: relative;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        flex-direction: ${({ imagePosition }) => (imagePosition === "right" ? "row" : "row-reverse")};
        align-items: flex-start;
        padding: 50px 70px;
    }

    ${({ theme }) => theme.breakpoints.xl.mediaquery} {
        padding: 50px 140px;
    }
`;

const ImageBlockWrapper = styled.div`
    flex-basis: 50%;
`;
