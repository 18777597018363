import { getConfig } from "@src/config";
import Image from "@src/shared/image/Image";
import * as React from "react";
import { PixelImageBlockData } from "src/blocks.generated";

export interface ImageDimensions {
    width: number;
    height: number;
}

interface PropsWithData<Data = unknown> {
    data: Data;
}

interface ImageBlockProps extends PropsWithData<PixelImageBlockData> {
    aspectRatio?: string | "inherit";
}

export function ImageBlock({ aspectRatio = "16x9", data: { damFile, cropArea, urlTemplate } }: ImageBlockProps): React.ReactElement {
    if (!damFile || !damFile.image) return <></>;

    const usedCropArea = cropArea ?? damFile.image.cropArea;

    let dimensions: ImageDimensions;

    // check if image is cropped
    if (usedCropArea.width && usedCropArea.height) {
        dimensions = {
            width: (usedCropArea.width * damFile.image.width) / 100,
            height: (usedCropArea.height * damFile.image.height) / 100,
        };
    } else {
        dimensions = {
            width: damFile.image.width,
            height: damFile.image.height,
        };
    }

    let usedAspectRatio: number;

    if (aspectRatio === "inherit") {
        usedAspectRatio = calculateInheritAspectRatio(damFile.image, usedCropArea);
    } else {
        usedAspectRatio = parseAspectRatio(aspectRatio);
    }

    const allowedSizes = getConfig("damAllowedImageSizes")
        .split(",")
        .map((size) => Number(size));

    const allowedWidth = getAllowedWidth(allowedSizes, dimensions.width);

    return <Image src={generateImageUrl({ src: urlTemplate, width: allowedWidth }, usedAspectRatio)} defaultLoading={false} />;
}

function generateImageUrl({ src, width }: { src: string; width: number }, aspectRatio: number): string {
    return src.replace("$resizeWidth", String(width)).replace("$resizeHeight", String(Math.ceil(width / aspectRatio)));
}

function calculateInheritAspectRatio(
    imageDimensions: ImageDimensions,
    cropArea: {
        focalPoint: "SMART" | "CENTER" | "NORTHWEST" | "NORTHEAST" | "SOUTHWEST" | "SOUTHEAST";
        width?: number;
        height?: number;
        x?: number;
        y?: number;
    },
): number {
    if (cropArea.focalPoint === "SMART") {
        return imageDimensions.width / imageDimensions.height;
    } else {
        if (cropArea.width === undefined || cropArea.height === undefined) {
            throw new Error("Missing crop dimensions");
        }

        return (cropArea.width * imageDimensions.width) / 100 / ((cropArea.height * imageDimensions.height) / 100);
    }
}

function parseAspectRatio(value: string): number {
    const [width = 1, height = 1] = value.split("x").map((v) => {
        let ret: number | undefined = Number(v);
        if (isNaN(ret)) ret = undefined;
        return ret;
    });
    return width / height;
}

function getAllowedWidth(allowedSizes: number[], width: number) {
    const sortedSizes = allowedSizes.sort(function (a, b) {
        return a - b;
    });

    return sortedSizes.find((size) => size >= width) ?? sortedSizes[sortedSizes.length - 1];
}
