import * as React from "react";
import { TextIconListBlockData } from "src/blocks.generated";
import styled from "styled-components";

import { TextIconListItem } from "./TextIconListItemBlock";

export function TextIconList({ blocks }: TextIconListBlockData): React.ReactElement {
    return (
        <TextIconListContainer>
            {blocks.map((block) => (
                <TextIconListItem key={block.key} props={block.props} />
            ))}
        </TextIconListContainer>
    );
}

const TextIconListContainer = styled.div`
    margin: 40px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 20px;
    row-gap: 15px;
    gap: 10px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        margin: 80px 0;
        padding: 0 70px;
        gap: 40px;
    }
    ${({ theme }) => theme.breakpoints.xl.mediaquery} {
        padding: 0 140px;
    }
`;
