import { PageBlocksBlockData } from "@src/blocks.generated";
import * as React from "react";

import { Faq } from "./FAQ/FaqBlock";
import { HeadlineBlock } from "./Headline/HeadlineBlock";
import { ImageRte } from "./ImageRte/ImageRteBlock";
import { SpaceBlock } from "./Space/SpaceBlock";
import { TextIconList } from "./TextIconList/TextIconListBlock";
import { TextImage } from "./TextImage/TextImageBlock";
import { TextVideo } from "./TextVideo/TextVideoBlock";

function renderBlock(type: string, props: any): React.ReactElement {
    switch (type) {
        case "space":
            return <SpaceBlock {...props} />;
        case "headline":
            return <HeadlineBlock {...props} />;
        case "textIconList":
            return <TextIconList {...props} />;
        case "faq":
            return <Faq {...props} />;
        case "textVideo":
            return <TextVideo {...props} />;
        case "textImage":
            return <TextImage {...props} />;
        case "imageRte":
            return <ImageRte {...props} />;
        default:
            return <p>Unknown block type: {type}</p>;
    }
}

export const PageContentBlock: React.FC<{ data: PageBlocksBlockData }> = ({ data: { blocks } }) => {
    return (
        <>
            {blocks.map((block: any) => (
                <React.Fragment key={block.key}>{renderBlock(block.type, block.props)}</React.Fragment>
            ))}
        </>
    );
};
