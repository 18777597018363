import { SpaceBlockData } from "@src/blocks.generated";
import * as React from "react";
import styled from "styled-components";

export function SpaceBlock({ height }: SpaceBlockData): React.ReactElement {
    return <Box height={height} />;
}

const Box = styled.div<{ height: number }>`
    height: ${(props) => props.height}px;
    background-color: red;
`;
