import * as ChevronDown from "@src/shared/assets/images/chevron_down.inline.svg";
import * as ChevronUp from "@src/shared/assets/images/chevron_up.inline.svg";
import styled from "styled-components";

interface TitleWrapperProps {
    open: boolean;
}

export const TitleWrapper = styled.div<TitleWrapperProps>`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    border-bottom: ${({ theme, open }) => (open ? "none" : `1px solid ${theme.colors.backgroundLightGray}`)};
`;

export const Title = styled.p`
    font-weight: 700;
    font-size: 20px;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    margin: 0;
`;

export const Text = styled.div`
    font-size: 16px;
    margin: 0;
    padding: 20px;
    background-color: ${({ theme }) => theme.colors.backgroundLightGray};
`;

export const OpenIcon = styled(ChevronUp)`
    width: 20px;
    height: 14px;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
`;

export const CloseIcon = styled(ChevronDown)`
    width: 20px;
    height: 14px;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
`;
