import styled from "styled-components";

export const Heading1 = styled.h1`
    margin: 19px 0 30px;
    font-size: 26px;
    line-height: 30px;
    font-weight: 500;
    text-transform: uppercase;
    white-space: pre-wrap;

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        margin-bottom: 40px;
        font-size: 32px;
        line-height: 40px;
    }
`;

export const Heading2 = styled.h2`
    margin: 19px 0 10px;
    font-size: 23px;
    line-height: 27px;
    font-weight: 500;
    white-space: pre-wrap;

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        margin-bottom: 20px;
        font-size: 29px;
        line-height: 37px;
    }
`;

export const Heading3 = styled.h3`
    margin: 19px 0 10px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    white-space: pre-wrap;

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        margin-bottom: 20px;
        font-size: 26px;
        line-height: 34px;
    }
`;

export const Heading4 = styled.h4`
    margin: 19px 0 10px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    white-space: pre-wrap;

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        margin-bottom: 20px;
        font-size: 24px;
        line-height: 32px;
    }
`;

export const Heading5 = styled.h5`
    margin: 19px 0 10px;
    font-size: 18px;
    line-height: 18px;
    font-weight: 500;
    white-space: pre-wrap;

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        margin-bottom: 20px;
        font-size: 22px;
        line-height: 30px;
    }
`;

export const Heading6 = styled.h6`
    margin: 19px 0 10px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    white-space: pre-wrap;

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        margin-bottom: 20px;
        font-size: 20px;
        line-height: 28px;
    }
`;

export const Paragraph = styled.p`
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 18px;
    white-space: pre-wrap;

    ${({ theme }) => theme.breakpoints.sm.mediaquery} {
        font-size: 14px;
        line-height: 20px;
    }
    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        font-size: 16px;
        line-height: 24px;
    }
`;

export const UnorderedList = styled.ul`
    margin: 40px 0;
    padding-left: 30px;
`;

export const ListItem = styled.li``;

interface RichTextContainerProps {
    variant: "light" | "dark";
}

export const RichTextContainer = styled.div<RichTextContainerProps>`
    color: ${({ theme, variant }) => (variant === "dark" ? theme.colors.porscheBankBlue : theme.colors.white)};
`;

export const HorizontalLine = styled.div`
    border-top: 3px solid ${({ theme }) => theme.colors.darkRed};
    width: 76px;
`;
