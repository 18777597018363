import styled from "styled-components";

export const TextVideoContainer = styled.div`
    margin: -40px 0 40px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        margin: 80px 0;
    }
`;

export const RichTextContainer = styled.div`
    flex-basis: 50%;
`;

export const TextVideoContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: ${({ theme }) => theme.colors.backgroundLightGray};
    padding: 40px 20px;
    z-index: 1;
    position: relative;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        flex-direction: row;
        align-items: center;
        padding: 50px 70px;
    }

    ${({ theme }) => theme.breakpoints.xl.mediaquery} {
        padding: 50px 140px;
    }
`;

export const VideoBlockWrapper = styled.div`
    flex-basis: 50%;
`;
