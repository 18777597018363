import { ImageRteBlockData } from "@src/blocks.generated";
import * as React from "react";
import redraft, { Renderers } from "redraft";

import { HeadlineSections } from "../Headline/HeadlineBlock";
import { ImageBlock } from "../Image/ImageBlock";
import { RichTextBlock } from "../RTE/RichTextBlock";
import {
    Body,
    CheckCircle,
    ChecklistContainer,
    ChecklistItem,
    ContentContainer,
    DesktopContainer,
    Heading1,
    Heading2,
    Heading3,
    HorizontalLine,
    ImageContainer,
    MobileContainer,
    Root,
    SubHeading,
} from "./ImageRteBlock.sc";

export function ImageRte({ image, rte, checklist, headline }: ImageRteBlockData): React.ReactElement {
    return (
        <Root>
            {headline?.headline.draftContent.blocks.length > 0 && headline?.headline.draftContent.blocks[0].text.length > 0 && (
                <MobileContainer>
                    <HorizontalLine />
                    {redraft(
                        headline.headline.draftContent,
                        headlineRenderer({
                            headlineSection: headline.headlineSection,
                        }),
                    )}
                </MobileContainer>
            )}

            <ImageContainer>{image.damFile?.image && <ImageBlock data={image} />}</ImageContainer>
            <ContentContainer>
                {headline?.headline.draftContent.blocks.length > 0 && headline?.headline.draftContent.blocks[0].text.length > 0 && (
                    <DesktopContainer>
                        <HorizontalLine />
                        {redraft(
                            headline.headline.draftContent,
                            headlineRenderer({
                                headlineSection: headline.headlineSection,
                            }),
                        )}
                    </DesktopContainer>
                )}

                <RichTextBlock draftContent={rte.draftContent} />
                {checklist.headline && <SubHeading>{checklist.headline}</SubHeading>}
                <ChecklistContainer>
                    {checklist.items.blocks.map((checklistItem) => (
                        <ChecklistItem key={checklistItem.key}>
                            <CheckCircle />
                            <div>{checklistItem.props.text}</div>
                        </ChecklistItem>
                    ))}
                </ChecklistContainer>
            </ContentContainer>
        </Root>
    );
}

type HeadlineRendersFn = (options: { headlineSection: HeadlineSections }) => Renderers;

const headlineRenderer: HeadlineRendersFn = ({ headlineSection }) => {
    return {
        inline: {
            // eslint-disable-next-line react/display-name
            BOLD: (children, { key }) => <strong key={key}>{children}</strong>,
            // eslint-disable-next-line react/display-name
            ITALIC: (children, { key }) => <em key={key}>{children}</em>,
        },
        blocks: {
            unstyled: (children) => {
                switch (headlineSection) {
                    case "header-one":
                        return children.map((child, index) => <Heading1 key={index}>{child}</Heading1>);
                    case "header-two":
                        return children.map((child, index) => <Heading2 key={index}>{child}</Heading2>);
                    case "header-three":
                        return children.map((child, index) => <Heading3 key={index}>{child}</Heading3>);
                    default:
                        return children.map((child, index) => <Body key={index}>{child}</Body>);
                }
            },
        },
    };
};
