import * as React from "react";
import { TextVideoBlockData } from "src/blocks.generated";

import { RichTextBlock } from "../RTE/RichTextBlock";
import { VideoBlock } from "../Video/VideoBlock";
import * as sc from "./TextVideoBlock.sc";

export function TextVideo({ text, video }: TextVideoBlockData): React.ReactElement {
    return (
        <sc.TextVideoContainer>
            <sc.TextVideoContent>
                <sc.RichTextContainer>
                    <RichTextBlock variant="dark" draftContent={text.draftContent} />
                </sc.RichTextContainer>
                <sc.VideoBlockWrapper>
                    <VideoBlock data={video} />
                </sc.VideoBlockWrapper>
            </sc.TextVideoContent>
        </sc.TextVideoContainer>
    );
}
