import * as React from "react";
import { YouTubeVideoBlockData } from "src/blocks.generated";
import styled from "styled-components";

interface VideoBlockProps {
    data: YouTubeVideoBlockData;
}

const getHeightInPercentForAspectRatio = (aspectRatio: YouTubeVideoBlockData["aspectRatio"]) => {
    switch (aspectRatio) {
        case "16X9":
            return 56.25;
        case "4X3":
            return 75;
    }
};

export const VideoBlock: React.FC<VideoBlockProps> = ({ data: { youtubeIdentifier, showControls, autoplay, aspectRatio } }) => {
    try {
        const url = new URL(youtubeIdentifier);
        const searchParams = url.searchParams;
        if (!searchParams.has("v")) {
            throw new Error("URL has no ID (v) param");
        }
        youtubeIdentifier = searchParams.get("v")!;
    } catch (error) {
        // no url, but ID was specified
    }

    return (
        <VideoContainer heightInPercent={getHeightInPercentForAspectRatio(aspectRatio)}>
            <VideoIFrame
                src={`https://www.youtube-nocookie.com/embed/${youtubeIdentifier}?&modestbranding=1&autoplay=${Number(autoplay)}&controls=${Number(
                    showControls,
                )}`}
                frameBorder="0"
            />
        </VideoContainer>
    );
};

interface VideoContainerProps {
    heightInPercent: number;
}

const VideoContainer = styled.div<VideoContainerProps>`
    height: 0;
    overflow: hidden;
    padding-top: ${({ heightInPercent }) => heightInPercent}%;
    position: relative;
`;

const VideoIFrame = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;
