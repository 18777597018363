import * as React from "react";
import { TitleTextListItemBlockData } from "src/blocks.generated";

import { RichTextBlock } from "../RTE/RichTextBlock";
import * as sc from "./TitleTextListItemBlock.sc";

export interface TitleTextListItemProps {
    props: TitleTextListItemBlockData;
}

export function TitleTextListItem({ props }: TitleTextListItemProps): React.ReactElement {
    const [open, setOpen] = React.useState(false);

    return (
        <div>
            <sc.TitleWrapper open={open} onClick={() => setOpen(!open)}>
                {props.title && <sc.Title>{props.title}</sc.Title>}
                {open ? <sc.OpenIcon /> : <sc.CloseIcon />}
            </sc.TitleWrapper>
            {open && (
                <sc.Text>
                    <RichTextBlock draftContent={props.text.draftContent} />
                </sc.Text>
            )}
        </div>
    );
}
