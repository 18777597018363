import * as React from "react";
import { TextIconListItemBlockData } from "src/blocks.generated";

import * as sc from "./TextIconListItemBlock.sc";

export interface TextIconListItemProps {
    props: TextIconListItemBlockData;
}

export function TextIconListItem({ props }: TextIconListItemProps): React.ReactElement {
    return (
        <sc.ContentWrapper>
            {props.icon.block ? <sc.Image src={props.icon.block.damFile?.fileUrl} /> : <sc.CheckCircle />}
            <sc.Content>
                {props.title && <sc.Title>{props.title}</sc.Title>}
                {props.text && <sc.Text>{props.text}</sc.Text>}
            </sc.Content>
        </sc.ContentWrapper>
    );
}
