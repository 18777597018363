import * as CheckMark from "@src/shared/assets/images/check_mark.inline.svg";
import styled from "styled-components";

export const Root = styled.div`
    display: flex;
    padding: 0 20px;
    gap: 30px;
    flex-direction: column;
    margin: 40px 0;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        padding: 0 70px;
        margin: 80px 0;
    }
    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        flex-direction: row;
    }
    ${({ theme }) => theme.breakpoints.xl.mediaquery} {
        padding: 0 140px;
    }
`;

export const ContentContainer = styled.div`
    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        flex-basis: 50%;
    }
`;

export const ImageContainer = styled.div`
    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        flex-basis: 50%;
    }
`;

export const SubHeading = styled.div`
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    margin: 25px 0;
`;

export const ChecklistContainer = styled.div`
    display: flex;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
`;

export const CheckCircle = styled(CheckMark)`
    color: ${({ theme }) => theme.colors.white};
    margin: 0 10px 0 0;
    background: ${({ theme }) => theme.colors.porscheBankBlue};
    border-radius: 50%;
`;

export const ChecklistItem = styled.div`
    display: flex;
    align-items: center;
    flex-basis: 100%;

    ${({ theme }) => theme.breakpoints.sm.mediaquery} {
        flex-basis: calc(50% - 10px);
    }

    ${({ theme }) => theme.breakpoints.xl.mediaquery} {
        flex-basis: calc(25% - 10px);
    }
`;

export const HorizontalLine = styled.div`
    border-top: 3px solid ${({ theme }) => theme.colors.darkRed};
    width: 76px;
`;

export const MobileContainer = styled.div`
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    text-transform: uppercase;

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        display: none;
    }
`;

export const DesktopContainer = styled.div`
    display: none;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    text-transform: uppercase;

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        display: block;
    }
`;

export const Heading1 = styled.h1`
    font-size: 26px;
    line-height: 30px;
    font-weight: 500;
    margin-top: 19px;

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        font-size: 32px;
        line-height: 40px;
    }
`;

export const Heading2 = styled.h2`
    font-size: 23px;
    line-height: 27px;
    font-weight: 500;
    margin-top: 19px;

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        font-size: 29px;
        line-height: 37px;
    }
`;

export const Heading3 = styled.h3`
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    margin-top: 19px;

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        font-size: 26px;
        line-height: 34px;
    }
`;

export const Body = styled.p`
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 18px;

    ${({ theme }) => theme.breakpoints.sm.mediaquery} {
        font-size: 14px;
        line-height: 20px;
    }
    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        font-size: 16px;
        line-height: 24px;
    }
`;
